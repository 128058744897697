import React, { useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Orders from '../components/Orders';
import { readBatch } from '../actions/checkInActions.js';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  fixedHeight: {
    height: 240,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));


function ReceptionScreen() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [RAid, setRAid] = useState('');
  const [batchID, setBatchID] = useState('');
  const handleRAsubmit = (e) => {
    e.preventDefault();
    if (("" === RAid.trim()) && ("" === batchID.trim())) {

    } else {
      dispatch(readBatch(RAid, batchID));
    }
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (


    <Grid container spacing={3}>
      {/* Formulario de lectura */}
      <Grid item xs={12} md={4} lg={3}>
        <Paper className={fixedHeightPaper}>
          <form className={classes.root} noValidate autoComplete="off" onSubmit={handleRAsubmit}>
            <TextField id="standard-basic" label="Colaborador ID" onChange={(e) => setRAid(e.target.value)} />
            <TextField id="standard-basic" label="Batch ID" onChange={(e) => setBatchID(e.target.value)} />
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Check
            </Button>
          </form>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4} lg={9}>
        <Paper className={fixedHeightPaper}>
          <form className={classes.root} noValidate autoComplete="off" onSubmit={handleRAsubmit}>
            <TextField id="standard-basic" label="Colaborador ID" onChange={(e) => setRAid(e.target.value)} />
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Check
            </Button>
          </form>
        </Paper>
      </Grid>


      {/* Datos de colabaorador */}
      <Grid item xs={12} md={8} lg={9}>
        <Paper className={classes.paper}>
          <Typography variant='h5'>Colaborador</Typography>
        </Paper>
      </Grid>

      {/* Recent Orders */}
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant='h5'>Documentos a ingresar</Typography>
          <Orders />
        </Paper>
      </Grid>
    </Grid>

  );
}

export default ReceptionScreen;