import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ApiURLs from '../config/ApiURLs';
/*REDUX*/
import { useDispatch } from 'react-redux';
import { deleteFile } from '../actions/documentAction';
/*END OF REDUX RELATED*/
const useStyles = makeStyles((theme) => ({
    descriptorContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        justifyContent: 'center'
    },
    deleteButton: {
        position: 'absolute',
        top: '-20px',
        right: '-15px',
        cursor: 'pointer'
    },
    customP: {
        marginBottom: '0 !important',
        cursor: 'pointer'
    }
}));
export default function SingleFileDescriptor(props) {
    const {fallbackText} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const files = useSelector(state => state.document.archivos);
    const focusedFile = files[0];

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    console.log('files', files);
    console.log('focusedFile', focusedFile)
    if (focusedFile != undefined) {
        console.log("efeee")
        return (
            <div className={classes.descriptorContainer}>
                <div className={classes.descriptorContainer}  style={{cursor:'pointer'}} onClick={(event) => { openInNewTab(ApiURLs.baseURL + 'file/get?id=' + focusedFile.id); event.stopPropagation(); }}>
                    <i class="fas fa-file-excel fa-3x"></i>
                    <p className={classes.customP}>Ver documento</p>
                </div>
                <div className={classes.deleteButton} onClick={(event) => { dispatch(deleteFile(focusedFile.id)); event.stopPropagation(); }}>
                    <i class="fas fa-trash-alt"></i>
                </div>
            </div>
        );
    }
    //the print fallback text
    return (
        <p>{fallbackText}</p>
    )
}