import {
	USER_SIGNIN_FAIL,
	USER_SIGNIN_REQUEST,
	USER_SIGNIN_SUCCESS,
	USER_SIGNOUT,
} from '../constants/userConstants.js';
/*import Axios from 'axios';*/
import Axios from '../services/Api.js';

export const signin = (username, password, remember) => async (dispatch) => {
	console.log(Axios);

	dispatch({ type: USER_SIGNIN_REQUEST, payload: { username, password } });
	try {
		//const {data} = await Axios.post('/glifos/auth',{username,password,remember});
		const { data } = await Axios.UserLogin({
			username,
			password,
			remember,
		});
		dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
		localStorage.setItem('userInfo', JSON.stringify(data));
	} catch (error) {
		dispatch({
			type: USER_SIGNIN_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};
export const signout = () => async (dispatch) => {
	console.log('signing out...');
	localStorage.removeItem('userInfo');
	dispatch({ type: USER_SIGNOUT });
};
