import React from 'react';

import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';

import Table from '../components/Table';

function DashboardScreen() {
	const data = [
		{
			Factura: '3E20074B',
			DTE: 336987312,
			Pedido: 926495,
			Sociedad: 'Ingenio Magdalena S.A.',
			Monto: 220.02,
			Fecha: '20/08/2021',
			Estatus: 'RECIBIDA',
		},
		{
			Factura: '3E20074C',
			DTE: 336987313,
			Pedido: 926496,
			Sociedad: 'Ingenio Magdalena S.A.',
			Monto: 114.5,
			Fecha: '20/08/2021',
			Estatus: 'RECIBIDA',
		},
		{
			Factura: '3E20074D',
			DTE: 336987314,
			Pedido: 926497,
			Sociedad: 'Ingenio Magdalena S.A.',
			Monto: 1720.13,
			Fecha: '20/08/2021',
			Estatus: 'RECIBIDA',
		},
	];
	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={8} lg={9}>
				<Typography
					component='h2'
					variant='h5'
					color='primary'
					gutterBottom>
					Bienvenido, Proveedor
				</Typography>
				<Grid
					container
					direction='column'
					justifyContent='flex-start'
					alignItems='flex-start'>
					<Typography
						component='h3'
						variant='h6'
						color='primary'
						gutterBottom>
						Facturas Recientes
					</Typography>
					<Table data={data} />
				</Grid>
			</Grid>
			<Grid item xs={12} md={4} lg={3}>
				<Typography
					component='h3'
					variant='h6'
					color='primary'
					gutterBottom>
					Acceso Rápido
				</Typography>
			</Grid>
		</Grid>
	);
}

export default DashboardScreen;
