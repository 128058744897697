import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { userSigninReducer } from './reducers/userReducer';
import { checkInReducer } from './reducers/checkInReducer';
import { documentReducer } from './reducers/documentReducer';

function getInitialUserInfoCheck() {
	let userLocal = localStorage.getItem('userInfo')
		? JSON.parse(localStorage.getItem('userInfo'))
		: null;
	if (null != userLocal) {
		/* if (userLocal.session.willExpire) {
            const currentTime = new Date();
            const expiryTime = new Date(userLocal.session.willExpire);
            if (currentTime.valueOf() > expiryTime.valueOf()) {
                localStorage.removeItem('userInfo');
                userLocal = null;
            }
        } */
		if (userLocal.access_token) {
		} else {
			localStorage.removeItem('userInfo');
			userLocal = null;
		}
	}
	return userLocal;
}

const initialState = {
	userSignin: {
		userInfo: getInitialUserInfoCheck(),
	},
	checkInBatchInfo: {},
	document: {
		loading: false,
		archivos: [],
	},
};
const reducer = combineReducers({
	userSignin: userSigninReducer,
	checkInBatchInfo: checkInReducer,
	document: documentReducer,
});
const composeEnhacer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	reducer,
	initialState,
	composeEnhacer(applyMiddleware(thunk))
);

export default store;
