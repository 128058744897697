const Style = theme => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        position:'relative'
    },
    stepIndicatorContainer:{
        position:'absolute',
        right:'10px',
        top:'10px'
    },
    columnarFlex:{
        display:'flex',
        flexDirection:'column'
    },
    subStep:{
        marginTop:'20px',
    },
    inputContainer:{
        display:'flex',
        alignItems:'center',
        marginTop:'10px',
    },
    inputLabel:{
        width:'100px',
        textAlign:'right',
        marginRight:'10px',
    },
    formFooterButtons:{
        display:'flex',
        marginTop:'20px'
    },
    formFooterLeftButton:{
        flex:'0.5',
        display:'flex',
        justifyContent:'flex-start'
    },
    formFooterRightButton:{
        flex:'0.5',
        display:'flex',
        justifyContent:'flex-end'
    },
    fileUploaderContainer:{
        marginTop:'20px'
    },
    dialogCenteredContent:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center'
    }

});
export default Style;