import React from 'react';

function NotFoundScreen() {
    return (
        <div>
            404 not found
        </div>
    );
  }
  
  export default NotFoundScreen;