import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from "../assets/jss/glifos/historyScreenStyle.js";
import { Typography } from '@material-ui/core';
import { InputLabel } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import TodayIcon from '@material-ui/icons/Today';

const useStyles = makeStyles(styles);
export default function HistoryScreen(props) {
    const classes = useStyles();
    const [data, setData] = React.useState({ isLoading: true, data: [] });
    
    const renderDateSelectors = ()=>{
        return (
            <div className={classes.horizontalContainer}>
                <div className={classes.horizontalContainer}>
                    <InputLabel >Fecha Inicial</InputLabel>
                    <TextField disabled variant={'outlined'} inputProps={{style:{padding:'0'}}} defaultValue="DD/MM/YYYY" />
                    <TodayIcon/>
                </div>
                    
                <div className={classes.horizontalContainer}>
                    <InputLabel >Fecha Inicial</InputLabel>
                    <TextField disabled variant={'outlined'}  defaultValue="DD/MM/YYYY" />
                    <TodayIcon/>
                </div>
            </div>
        )
    };
    
    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: '10px' }}>Archivo Histórico - Organismo Judicial (OJ)</Typography>
            {renderDateSelectors()}
        </div>
    );
}