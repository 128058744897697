import React from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Acordeon from '../components/Final/Acordeon';

function LocationScreen() {

    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
        fixedHeight: {
            height: 540,
        },
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
        },
    }));

    const classes = useStyles();

    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    return (
        <Grid item xs={12} md={12} lg={12}>
            <Paper className={fixedHeightPaper}>
                <Acordeon />
            </Paper>
        </Grid>
    );
}

export default LocationScreen;