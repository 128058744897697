import { UPLOAD_REQUEST, UPLOAD_REQUEST_SUCCESS, UPLOAD_REQUEST_ERROR, DELETE_REQUEST, DELETE_REQUEST_ERROR, DELETE_REQUEST_SUCCESS } from "../constants/documentConstants";

const initialState = {
    loading: false,
    archivos: []
}


export const documentReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_REQUEST:
        case UPLOAD_REQUEST: {
            return { ...state, loading: true };
        }
        case UPLOAD_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                archivos: [...state.archivos, action.payload]
            }
        }
        case DELETE_REQUEST_ERROR:
        case UPLOAD_REQUEST_ERROR: {
            return { ...state, loading: false };
        }
        case DELETE_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                archivos: state.archivos.filter(archivo => archivo.id !== action.payload)
            }
        }
        default: return state;
    }
}
