import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import Link from '@material-ui/core/Link';

import { deleteFile } from '../../actions/documentAction';

const ListItemCustom = ({ name, size, id }) => {
    const dispatch = useDispatch();

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const deleteFilee = id => {
        dispatch(deleteFile(id));

    };


    return (
        <ListItem >
            <ListItemAvatar>
                <Avatar>
                    <PictureAsPdfIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                onClick={() => openInNewTab('https://archive.glifos.local2/glifos/file/get/viewer/pdf/' + id)}
                primary={name}
                secondary={"Tamano:" + size + "kb"}
            />
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete">
                    <Link href={"https://archive.glifos.local2/glifos/file/download/" + id} download={name + ".pdf"}>
                        <VisibilityIcon onClick={() => deleteFilee(id)} /></Link>
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem >
    );
}

export default ListItemCustom;