const Style = theme => ({
    horizontalContainer:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    textField:{
        padding:'0 !imporant'
    }

});
export default Style;