import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AcordeonItem from './AcordeonItem';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function Acordeon() {
    const secciones = [
        {
            id: 1,
            titulo: "Titulo 1",
            cuerpo: "Este es el cuerpo de la numero 1"
        }, {
            id: 2,
            titulo: "Titulo 2",
            cuerpo: "Este es el cuerpo de la numero 2"
        }, {
            id: 3,
            titulo: "Titulo 3",
            cuerpo: "Este es el cuerpo de la numero 3"
        }, {
            id: 4,
            titulo: "Titulo 4",
            cuerpo: "Este es el cuerpo de la numero 4"
        }, {
            id: 5,
            titulo: "Titulo 5",
            cuerpo: "Este es el cuerpo de la numero 5"
        },
    ];
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {secciones.map(seccion => (
                <AcordeonItem key={seccion.id} titulo={seccion.titulo} cuerpo={seccion.cuerpo} />
            ))}

        </div>
    );
}