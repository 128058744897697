import { READ_BATCH_FAIL, READ_BATCH_REQUEST, READ_BATCH_SUCCESS } from "../constants/checkinConstants";

export const checkInReducer = (state={loading:false,checkInBatchInfo:{}},action) =>{
    switch (action.type){
        case READ_BATCH_REQUEST:{
            return {...state,loading:true};
        }
        case READ_BATCH_SUCCESS:{
            return {...state,loading: false, checkInBatchInfo:action.payload};
        }
        case READ_BATCH_FAIL:{
            return {...state,loading: false, error: action.payload};
        }

        default: return state;
    }
}