import React, {Component, useState} from 'react';
import {Button, Hidden, TextareaAutosize, TextField} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import {
    Prompt
  } from "react-router-dom";

const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
  });

class CatalogingScreen extends Component{
    constructor(props) {
        super(props);
        this.state={
            hasChanges : false
        };
    }
    componentDidMount(){
        const script = document.createElement("script");
        script.src = "gxCreator.js";
        script.async = true;
        script.onload = () => this.scriptLoaded();
        document.body.appendChild(script);
        window.gxCreatorHasChanges=false;
        document.querySelector('[data-gx-root]').addEventListener("change",(ev)=>{
            console.log("esta cambiando");
            switch(ev.target.tagName.toLowerCase()){
                case 'input':case 'textarea':case 'select':case 'checkbox':{
                    this.setState({ hasChanges: true});
                    window.gxCreatorHasChanges=true;
                }break;
            }
        });
        document.querySelector('[data-gx-root]').addEventListener("keyup",(ev)=>{
            switch(ev.target.tagName.toLowerCase()){
                case 'input':case 'textarea':case 'select':case 'checkbox':{
                    this.setState({ hasChanges: true});
                    window.gxCreatorHasChanges=true;
                }break;
            }
        });
        window.onbeforeunload=function(){
            if (window.gxCreatorHasChanges) 
            return 'Desea abandonar sus cambios?';
        };

    }
    scriptLoaded(){
        window.gxCreator=true;
    }
    
        

    render() {
        const submitHandler = (e) =>{
            e.preventDefault();
            let xmlRendered=window.getCreator().getMyXMLString();
            console.log(xmlRendered);
            alert(xmlRendered);
            //TODO: SIGN IN ACTION
        }
        

        return (
            <div>
                Cataloging Screen !
                <Prompt
                    when={this.state.hasChanges}
                    message={location =>
                    `¿Está seguro que desea abandaonar sus Cambios?`
                    }
                />
                <div data-gx-root='typeOfCreator' onChange={ event =>{
                    console.log("esta cambiando");
                    switch(event.target.tagName.toLowerCase()){
                        case 'input':case 'textarea':case 'select':case 'checkbox':{
                            this.setState({ hasChanges: true});
                        }break;
                    }
                }}>

                </div>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={submitHandler}
                    >
                    Sign In
                    </Button>
            </div>
        );
    }
}
  export default CatalogingScreen;