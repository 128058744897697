import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Carta from './Card';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function Cartas() {

    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <Grid container justify="center" spacing="2">
                {[0, 1, 2].map((value) => (
                    <Grid key={value} item>
                        <Carta />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}