import { DataGrid } from '@material-ui/data-grid';
export default function FileDetailTable(props){
    const {headers, rows} = props;
    
    const createHeaderRows = ()=>{
        ///si no trae headers, comenazmos a generarlos a partir del contenido, pero ojo. Para esto se asume que todas tendran las mismas columnas. Por lo que se toma el elemento 0 como base
        const pivot = rows[0];
        const headerRows = Object.keys(pivot).map((element,index)=>{
            return (
                {
                    field:'col'+index,
                    headerName: element,
                    width:200
                }
            );
        });
        return headerRows;
    };

    const createContentRows = ()=>{
        return rows.map((element,id)=>{
            var aux = {};
            Object.values(element).map((element,index)=>{
                aux["col"+index]=element;
            });
            return (
                {id,
                ...aux}
            );
        });
    };
    return (
        <div style={{ height: 500, width: '100%' }}>
			<DataGrid rows={createContentRows()} columns={headers?headers:createHeaderRows()} disableColumnMenu />
		</div>
    );
}