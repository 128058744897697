import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	iconSpace: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant='h6'>{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label='close'
					className={classes.closeButton}
					onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export default function Diealog(props) {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<InfoIcon
				style={{ cursor: 'pointer' }}
				color='disabled'
				fontSize='large'
				onClick={handleClickOpen}
			/>
			<Dialog
				fullWidth={true}
				maxWidth={'md'}
				onClose={handleClose}
				aria-labelledby='customized-dialog-title'
				open={open}>
				<DialogTitle id='customized-dialog-title' onClose={handleClose}>
					FACTURA
				</DialogTitle>
				<DialogContent dividers>
					<Typography gutterBottom>
						{`Factura: ${props.info.Factura} Número de DTE: ${props.info.DTE}`}
					</Typography>
					<Typography gutterBottom>
						{`No. Pedido: ${props.info.Pedido}`}
					</Typography>
					<Typography gutterBottom>
						{`Sociedad: ${props.info.Sociedad}`}
					</Typography>
					<Typography gutterBottom>
						{`Monto: ${props.info.Monto}`}
					</Typography>
					<Typography gutterBottom>
						{`Fecha de registro: ${props.info.Fecha}`}
					</Typography>
					<Typography gutterBottom>
						{`Estado: ${props.info.Estatus}`}
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleClose} color='primary'>
						Cerrar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
