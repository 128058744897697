import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import DescriptionIcon from '@material-ui/icons/Description';

import AssignmentIcon from '@material-ui/icons/Assignment';
import PinDropIcon from '@material-ui/icons/PinDrop';
import ScannerIcon from '@material-ui/icons/Scanner';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import EventIcon from '@material-ui/icons/Event';
import { NavLink } from 'react-router-dom';

export function MainListItems(props) {
	const textColor = {
		color: '#4D4D4D',
	};

	return (
		<div>
			{/*<NavLink to='/dashboard' style={{ textDecoration: 'none' }}>
				<ListItem button>
					<ListItemIcon>
						<DashboardIcon />
					</ListItemIcon>
					<ListItemText
						primaryTypographyProps={{ style: textColor }}
						primary='Inicio'
					/>
				</ListItem>
			</NavLink>
			{props.userInfo.roles.find(
				(role) => role.key === 'administrator'
			) && (*/}
				<NavLink to='/registry' style={{ textDecoration: 'none' }}>
					<ListItem button>
						<ListItemIcon>
							<DescriptionIcon />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ style: textColor }}
							primary='Registro Flujograma'
						/>
					</ListItem>
				</NavLink>
				<NavLink to='/registry-indicadores' style={{ textDecoration: 'none' }}>
					<ListItem button>
						<ListItemIcon>
							<DescriptionIcon />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ style: textColor }}
							primary='Registro Indicadores'
						/>
					</ListItem>
				</NavLink>
			{/*})*/}
			
			{/* <NavLink to='/circulation' style={{ textDecoration: 'none' }}>
				<ListItem button>
					<ListItemIcon>
						<ShoppingCartIcon />
					</ListItemIcon>
					<ListItemText
						primaryTypographyProps={{ style: textColor }}
						primary='Circulación'
					/>
				</ListItem>
			</NavLink>
			<NavLink to='/location' style={{ textDecoration: 'none' }}>
				<ListItem button>
					<ListItemIcon>
						<PinDropIcon />
					</ListItemIcon>
					<ListItemText
						primaryTypographyProps={{ style: textColor }}
						primary='Ubicación'
					/>
				</ListItem>
			</NavLink>
			<NavLink to='/digitization' style={{ textDecoration: 'none' }}>
				<ListItem button>
					<ListItemIcon>
						<ScannerIcon />
					</ListItemIcon>
					<ListItemText
						primaryTypographyProps={{ style: textColor }}
						primary='Copia Digital'
					/>
				</ListItem>
			</NavLink>
			<NavLink to='/reception' style={{ textDecoration: 'none' }}>
				<ListItem button>
					<ListItemIcon>
						<PlaylistAddCheckIcon />
					</ListItemIcon>
					<ListItemText
						primaryTypographyProps={{ style: textColor }}
						primary='Recepción'
					/>
				</ListItem>
			</NavLink> */}
		</div>
	);
}

export function SecondaryListItems(props) {
	if (!props.userInfo.roles.find((role) => role.key === 'administrator'))
		return <></>;
	return (
		<div>
			<ListSubheader>Administración</ListSubheader>
			<ListItem button>
				<ListItemIcon>
					<AssignmentIcon />
				</ListItemIcon>
				<ListItemText primary='Tabla de valoración' />
			</ListItem>
			<ListItem button>
				<ListItemIcon>
					<AssignmentIcon />
				</ListItemIcon>
				<ListItemText primary='Editor de maestros' />
			</ListItem>
			<ListItem button>
				<ListItemIcon>
					<EventIcon />
				</ListItemIcon>
				<ListItemText primary='Calendario' />
			</ListItem>
		</div>
	);
}
